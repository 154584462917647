import React, { createContext, useEffect, useState } from "react";
import Loading from "./Loading";
import dataHelper from "../helpers/dataHelper";
import MealList from "./parts/MealList";
import { useNavigate } from "react-router";

export const NutritionClientContext = createContext(undefined);

export default function PortalNutritionPlanModule() {
  const [loaded, setLoaded] = useState(true);
  const [nutritionPlan, setNutritionPlan] = useState(null);
  const [nutritionNotes, setNutritionNotes] = useState([]);
  // let nutritionNotes = [];
  const [clientContext, setClientContext] = useState(true);
  const nav = useNavigate()

  const [displayMoreNotes, setDisplayMoreNotes] = useState(false);
  const [displayMoreNotesText, setDisplayMoreNotesText] = useState('More');


  useEffect(async () => {
    await fetchData()
  }, []);

  const fetchData = async function () {
    setLoaded(false);
    const dman = new dataHelper();
    const response = await dman.getCurrentNutritionPlan();
    const result = await response.json();
    // if (result.status != `ok`) {
    //   nav(`/login`);
    // }

    const { data } = result;
    setNutritionPlan(data);


    // Notes

    const notesRes = await dman.getNutritionNotes();
    const notesResult = await notesRes.json();
    const { data: notesData } = notesResult;

    // console.log("once", notesData);
    setNutritionNotes(notesData.sort((a, b) => (new Date(a.weekOf)) > (new Date(b.weekOf)) ? -1 : 0));

    // console.log('twice', nutritionNotes);

    setLoaded(true);

  };

  function handleChangeShowMore() {
    return () => {
      if (displayMoreNotes) {
        setDisplayMoreNotes(false);
        setDisplayMoreNotesText('More')
        return;
      }
      setDisplayMoreNotes(true);
      setDisplayMoreNotesText('Less')
    }
  }


  if (loaded) {
    return (
      <div className="portalNutritionPlanModule">
        <h2>Nutrition Plan</h2>
        <p>See your nutrition plan here</p>
        {nutritionPlan ? (
          <>
            <h3>Notes</h3>

            {/* {
              nutritionNotes.map((weeklyNote, noteIdx) => {
                return (
                  <div key={`${weeklyNote._id}`} style={styleSheet.notesContainer}>
                    <p>Week of: {weeklyNote.weekOf}</p>
                    <textarea readOnly style={styleSheet.portalNutritionPlanModule}>{weeklyNote.note}</textarea>
                  </div>
                )
              })
            } */}


            {
              displayMoreNotes
                ? (nutritionNotes ?? []).map((weeklyNote, noteIdx) => {
                  return (
                    <div key={`${weeklyNote._id}`} style={styleSheet.notesContainer}>
                      <p>Week of: {weeklyNote.weekOf}</p>
                      <textarea readOnly style={styleSheet.portalNutritionPlanModule}>{weeklyNote.note}</textarea>
                    </div>
                  )
                })
                :
                nutritionNotes.length == 0
                  ? <p>Client has no Nutrition Notes</p>


                  : (nutritionNotes ?? []).map((weeklyNote, noteIdx) => {
                    if (noteIdx > 1) {
                      return;
                    }
                    return (
                      <div key={`${weeklyNote._id}`} style={styleSheet.notesContainer}>
                        <p>Week of: {weeklyNote.weekOf}</p>
                        <textarea readOnly style={styleSheet.portalNutritionPlanModule}>{weeklyNote.note}</textarea>
                      </div>
                    )
                  })

            }
            {
              nutritionNotes.length > 2
                ? <button type="button" style={styleSheet.buttonStyles} onClick={handleChangeShowMore()}>{displayMoreNotesText}</button>
                : <></>
            }


            {/* <textarea readOnly style={styleSheet.portalNutritionPlanModule}>{nutritionPlan.notes}</textarea> */}

            {/* Down here, we'll be keeping things the same, but we need a new api call */}

            <h3>General Information</h3>
            <div className="top">
              <div className="section">
                <h3>Meals</h3>
                <div className="label">
                  <label>Meals: </label>
                  <p>{JSON.parse(nutritionPlan.meals).quantity}</p>
                </div>
                <div className="label">
                  <label>Protein: </label>
                  <p>{JSON.parse(nutritionPlan.meals).proteinEach}</p>
                </div>
                <div className="label">
                  <label>Carbs: </label>
                  <p>{JSON.parse(nutritionPlan.meals).carbsEach}</p>
                </div>
                <div className="label">
                  <label>Fats: </label>
                  <p>{JSON.parse(nutritionPlan.meals).fatsEach}</p>
                </div>
              </div>
              <div className="section">
                <h3>Macros</h3>
                <div className="label">
                  <label>Protein: </label>
                  <p>{JSON.parse(nutritionPlan.macros).protein}</p>
                </div>
                <div className="label">
                  <label>Carbs: </label>
                  <p>{JSON.parse(nutritionPlan.macros).carbs}</p>
                </div>
                <div className="label">
                  <label>Fats: </label>
                  <p>{JSON.parse(nutritionPlan.macros).fats}</p>
                </div>
              </div>
              <div className="section">
                <h3>Fat Sources</h3>
                <div className="label">
                  <label>Oils: </label>
                  <p>{JSON.parse(nutritionPlan.fats).oils}</p>
                </div>
                <div className="label">
                  <label>Nuts: </label>
                  <p>{JSON.parse(nutritionPlan.fats).nuts}</p>
                </div>
                <div className="label">
                  <label>Nut Butters: </label>
                  <p>{JSON.parse(nutritionPlan.fats).nb}</p>
                </div>
                <div className="label">
                  <label>Avocado: </label>
                  <p>{JSON.parse(nutritionPlan.fats).avocado}</p>
                </div>
                <div className="label">
                  <label>Butter: </label>
                  <p>{JSON.parse(nutritionPlan.fats).butter}</p>
                </div>
              </div>
            </div>
            <h3>Meals</h3>
            <div className="mealList">
              <MealList
                mealData={JSON.parse(nutritionPlan.plan)}
                count={JSON.parse(nutritionPlan.meals).quantity}
                clientContext={true}
              />
            </div>
          </>
        ) : (
          <strong>Please come back later! Your plan is not ready yet.</strong>
        )}
      </div>
    );
  } else {
    return <Loading />;
  }
};

const styleSheet = {
  notesContainer: {
    width: '100%',
  },
  portalNutritionPlanModule: {
    width: '90%',
    height: 150,
    overflow: 'hidden',
  },
  buttonStyles: {
    maxWidth: 100,
    marginLeft: 20,
  }
}
