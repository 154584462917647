import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function TermsOfUse(props) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  return (
    <div className="legal">
      <p>
        <strong>Terms of Use</strong>
      </p>
      <p>Our Terms of Use were last updated on 28 Oct 2022.</p>
      <p>
        Please read these terms and conditions carefully before using Our
        Service.
      </p>
      <p>
        <strong>Interpretation and Definitions</strong>
      </p>
      <p>
        <strong>Interpretation</strong>
      </p>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <p>
        <strong>Definitions</strong>
      </p>
      <p>For the purposes of these Terms of Use:</p>
      <ul>
        <li>
          <p>
            <strong>"Affiliate"</strong> means an entity that controls, is
            controlled by or is under common control with a party, where
            "control" means ownership of 50% or more of the shares, equity
            interest or other securities entitled to vote for election of
            directors or other managing authority.
          </p>
        </li>
        <li>
          <p>
            <strong>"Account"</strong> means a unique account created for You to
            access our Service or parts of our Service.
          </p>
        </li>
        <li>
          <p>
            <strong>"Company"</strong> (referred to as either "the Company",
            "We", "Us" or "Our" in this Agreement) refers to {process.env.MONGO_DB_IN_USE == 'UserDB' ? 'Trained By Turp' : 'Body By Reyes'}.
          </p>
        </li>
        <li>
          <p>
            <strong>"Country"</strong> refers to United States of America.
          </p>
        </li>
        <li>
          <p>
            <strong>"Content"</strong> refers to content such as text, images,
            or other information that can be posted, uploaded, linked to or
            otherwise made available by You, regardless of the form of that
            content.
          </p>
        </li>
        <li>
          <p>
            <strong>"Device"</strong> means any device that can access the
            Service such as a computer, a cellphone or a digital tablet.
          </p>
        </li>
        <li>
          <p>
            <strong>"Feedback"</strong> means feedback, innovations or
            suggestions sent by You regarding the attributes, performance or
            features of our Service.
          </p>
        </li>
        <li>
          <p>
            <strong>"Service"</strong> refers to the Website.
          </p>
        </li>
        <li>
          <p>
            <strong>"Terms of Use"</strong> (also referred as "Terms" or "Terms
            of Use") mean these Terms of Use that form the entire agreement
            between You and the Company regarding the use of the Service. This
            Terms of Use Agreement was generated by{" "}
            <a href="https://www.termsfeed.com/blog/sample-terms-of-use-template/">
              TermsFeed Terms of Use Template
            </a>
            .
          </p>
        </li>
        <li>
          <p>
            <strong>"Third-party Social Media Service"</strong> means any
            services or content (including data, information, products or
            services) provided by a third-party that may be displayed, included
            or made available by the Service.
          </p>
        </li>
        <li>
          <p>
            <strong>"Website"</strong> refers to {process.env.MONGO_DB_IN_USE == 'UserDB' ? 'Trained By Turp' : 'Body By Reyes'}, accessible
            from{" "}
            <a className="red-text" href={process.env.REACT_APP_BASE_URL}></a>
          </p>
        </li>
        <li>
          <p>
            <strong>"You"</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </p>
        </li>
      </ul>
      <p>
        <strong>Acknowledgment</strong>
      </p>
      <p>
        These are the Terms of Use governing the use of this Service and the
        agreement that operates between You and the Company. These Terms of Use
        set out the rights and obligations of all users regarding the use of the
        Service.
      </p>
      <p>
        Your access to and use of the Service is conditioned on Your acceptance
        of and compliance with these Terms of Use. These Terms of Use apply to
        all visitors, users and others who access or use the Service.
      </p>
      <p>
        By accessing or using the Service You agree to be bound by these Terms
        of Use. If You disagree with any part of these Terms of Use then You may
        not access the Service.
      </p>
      <p>
        You represent that you are over the age of 18. The Company does not
        permit those under 18 to use the Service.
      </p>
      <p>
        Your access to and use of the Service is also conditioned on Your
        acceptance of and compliance with the Privacy Policy of the Company. Our
        Privacy Policy describes Our policies and procedures on the collection,
        use and disclosure of Your personal information when You use the
        Application or the Website and tells You about Your privacy rights and
        how the law protects You. Please read Our Privacy Policy carefully
        before using Our Service.
      </p>
      <p>
        <strong>Copyright Policy</strong>
      </p>
      <p>
        <strong>Intellectual Property Infringement</strong>
      </p>
      <p>
        We respect the intellectual property rights of others. It is Our policy
        to respond to any claim that Content posted on the Service infringes a
        copyright or other intellectual property infringement of any person.
      </p>
      <p>
        If You are a copyright owner, or authorized on behalf of one, and You
        believe that the copyrighted work has been copied in a way that
        constitutes copyright infringement that is taking place through the
        Service, You must submit Your notice in writing to the attention of our
        copyright agent via email (
        <a className="red-text" href="mailto:trainedbyturp@gmail.com">
          trainedbyturp@gmail.com
        </a>
        ) and include in Your notice a detailed description of the alleged
        infringement.
      </p>
      <p>
        You may be held accountable for damages (including costs and attorneys'
        fees) for misrepresenting that any Content is infringing Your copyright.
      </p>
      <p>
        <strong>
          DMCA Notice and DMCA Procedure for Copyright Infringement Claims
        </strong>
      </p>
      <p>
        You may submit a notification pursuant to the Digital Millennium
        Copyright Act (DMCA) by providing our Copyright Agent with the following
        information in writing (see 17 U.S.C 512(c)(3) for further detail):
      </p>
      <ul>
        <li>
          An electronic or physical signature of the person authorized to act on
          behalf of the owner of the copyright's interest.
        </li>
        <li>
          A description of the copyrighted work that You claim has been
          infringed, including the URL (i.e., web page address) of the location
          where the copyrighted work exists or a copy of the copyrighted work.
        </li>
        <li>
          Identification of the URL or other specific location on the Service
          where the material that You claim is infringing is located.
        </li>
        <li>Your address, telephone number, and email address.</li>
        <li>
          A statement by You that You have a good faith belief that the disputed
          use is not authorized by the copyright owner, its agent, or the law.
        </li>
        <li>
          A statement by You, made under penalty of perjury, that the above
          information in Your notice is accurate and that You are the copyright
          owner or authorized to act on the copyright owner's behalf.
        </li>
      </ul>
      <p>
        You can contact our copyright agent via email (
        <a className="red-text" href="mailto:trainedbyturp@gmail.com">
          trainedbyturp@gmail.com
        </a>
        ). Upon receipt of a notification, the Company will take whatever
        action, in its sole discretion, it deems appropriate, including removal
        of the challenged content from the Service.
      </p>
      <p>
        <strong>Intellectual Property</strong>
      </p>
      <p>
        The Service and its original content (excluding Content provided by You
        or other users), features and functionality are and will remain the
        exclusive property of the Company and its licensors.
      </p>
      <p>
        The Service is protected by copyright, trademark, and other laws of both
        the Country and foreign countries.
      </p>
      <p>
        Our trademarks and trade dress may not be used in connection with any
        product or service without the prior written consent of the Company.
      </p>
      <p>
        <strong>Your Feedback to Us</strong>
      </p>
      <p>
        You assign all rights, title and interest in any Feedback You provide
        the Company. If for any reason such assignment is ineffective, You agree
        to grant the Company a non-exclusive, perpetual, irrevocable, royalty
        free, worldwide right and license to use, reproduce, disclose,
        sub-license, distribute, modify and exploit such Feedback without
        restriction.
      </p>
      <p>
        <strong>Links to Other Websites</strong>
      </p>
      <p>
        Our Service may contain links to third-party web sites or services that
        are not owned or controlled by the Company.
      </p>
      <p>
        The Company has no control over, and assumes no responsibility for, the
        content, privacy policies, or practices of any third party web sites or
        services. You further acknowledge and agree that the Company shall not
        be responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with the use of or
        reliance on any such content, goods or services available on or through
        any such web sites or services.
      </p>
      <p>
        We strongly advise You to read the terms and conditions and privacy
        policies of any third-party web sites or services that You visit.
      </p>
      <p>
        <strong>Limitation of Liability</strong>
      </p>
      <p>
        Notwithstanding any damages that You might incur, the entire liability
        of the Company and any of its suppliers under any provision of this
        Terms and Your exclusive remedy for all of the foregoing shall be
        limited to the amount actually paid by You through the Service or 100
        USD if You haven't purchased anything through the Service.
      </p>
      <p>
        To the maximum extent permitted by applicable law, in no event shall the
        Company or its suppliers be liable for any special, incidental,
        indirect, or consequential damages whatsoever (including, but not
        limited to, damages for loss of profits, loss of data or other
        information, for business interruption, for personal injury, loss of
        privacy arising out of or in any way related to the use of or inability
        to use the Service, third-party software and/or third-party hardware
        used with the Service, or otherwise in connection with any provision of
        this Terms), even if the Company or any supplier has been advised of the
        possibility of such damages and even if the remedy fails of its
        essential purpose.
      </p>
      <p>
        Some states do not allow the exclusion of implied warranties or
        limitation of liability for incidental or consequential damages, which
        means that some of the above limitations may not apply. In these states,
        each party's liability will be limited to the greatest extent permitted
        by law.
      </p>
      <p>
        <strong>"AS IS" and "AS AVAILABLE" Disclaimer</strong>
      </p>
      <p>
        The Service is provided to You "AS IS" and "AS AVAILABLE" and with all
        faults and defects without warranty of any kind. To the maximum extent
        permitted under applicable law, the Company, on its own behalf and on
        behalf of its Affiliates and its and their respective licensors and
        service providers, expressly disclaims all warranties, whether express,
        implied, statutory or otherwise, with respect to the Service, including
        all implied warranties of merchantability, fitness for a particular
        purpose, title and non-infringement, and warranties that may arise out
        of course of dealing, course of performance, usage or trade practice.
        Without limitation to the foregoing, the Company provides no warranty or
        undertaking, and makes no representation of any kind that the Service
        will meet Your requirements, achieve any intended results, be compatible
        or work with any other software, applications, systems or services,
        operate without interruption, meet any performance or reliability
        standards or be error free or that any errors or defects can or will be
        corrected.
      </p>
      <p>
        Without limiting the foregoing, neither the Company nor any of the
        company's provider makes any representation or warranty of any kind,
        express or implied: (i) as to the operation or availability of the
        Service, or the information, content, and materials or products included
        thereon; (ii) that the Service will be uninterrupted or error-free;
        (iii) as to the accuracy, reliability, or currency of any information or
        content provided through the Service; or (iv) that the Service, its
        servers, the content, or e-mails sent from or on behalf of the Company
        are free of viruses, scripts, trojan horses, worms, malware, timebombs
        or other harmful components.
      </p>
      <p>
        Some jurisdictions do not allow the exclusion of certain types of
        warranties or limitations on applicable statutory rights of a consumer,
        so some or all of the above exclusions and limitations may not apply to
        You. But in such a case the exclusions and limitations set forth in this
        section shall be applied to the greatest extent enforceable under
        applicable law.
      </p>
      <p>
        <strong>Governing Law</strong>
      </p>
      <p>
        The laws of the Country, excluding its conflicts of law rules, shall
        govern this Terms and Your use of the Service. Your use of the
        Application may also be subject to other local, state, national, or
        international laws.
      </p>
      <p>
        <strong>Disputes Resolution</strong>
      </p>
      <p>
        If You have any concern or dispute about the Service, You agree to first
        try to resolve the dispute informally by contacting the Company.
      </p>
      <p>
        <strong>For European Union (EU) Users</strong>
      </p>
      <p>
        If You are a European Union consumer, you will benefit from any
        mandatory provisions of the law of the country in which you are resident
        in.
      </p>
      <p>
        <strong>United States Legal Compliance</strong>
      </p>
      <p>
        You represent and warrant that (i) You are not located in a country that
        is subject to the United States government embargo, or that has been
        designated by the United States government as a "terrorist supporting"
        country, and (ii) You are not listed on any United States government
        list of prohibited or restricted parties.
      </p>
      <p>
        <strong>Severability and Waiver</strong>
      </p>
      <p>
        <strong>Severability</strong>
      </p>
      <p>
        If any provision of these Terms is held to be unenforceable or invalid,
        such provision will be changed and interpreted to accomplish the
        objectives of such provision to the greatest extent possible under
        applicable law and the remaining provisions will continue in full force
        and effect.
      </p>
      <p>
        <strong>Waiver</strong>
      </p>
      <p>
        Except as provided herein, the failure to exercise a right or to require
        performance of an obligation under these Terms shall not effect a
        party's ability to exercise such right or require such performance at
        any time thereafter nor shall be the waiver of a breach constitute a
        waiver of any subsequent breach.
      </p>
      <p>
        <strong>Translation Interpretation</strong>
      </p>
      <p>
        These Terms of Use may have been translated if We have made them
        available to You on our Service.
        <br />
        You agree that the original English text shall prevail in the case of a
        dispute.
      </p>
      <p>
        <strong>Changes to These Terms of Use</strong>
      </p>
      <p>
        We reserve the right, at Our sole discretion, to modify or replace these
        Terms at any time. If a revision is material We will make reasonable
        efforts to provide at least 30 days' notice prior to any new terms
        taking effect. What constitutes a material change will be determined at
        Our sole discretion.
      </p>
      <p>
        By continuing to access or use Our Service after those revisions become
        effective, You agree to be bound by the revised terms. If You do not
        agree to the new terms, in whole or in part, please stop using the
        website and the Service.
      </p>
      <p>
        <strong>Contact Us</strong>
      </p>
      <p>
        If you have any questions about these Terms of Use, You can contact us:
      </p>
      <ul>
        <li>
          By visiting this page on our website:{" "}
          <Link to="/contact">
            <span className="red-text">Contact Us</span>
          </Link>
        </li>
        <li>
          By sending us an email:{" "}
          <a className="red-text" href="mailto:trainedbyturp@gmail.com">
            trainedbyturp@gmail.com
          </a>
        </li>
      </ul>
      <p></p>
    </div>
  );
}
