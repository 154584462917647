import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function RefundPolicy(props) {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  return (
    <div className="legal">
      <p>
        <strong>Refund Policy</strong>
      </p>
      <p>Our Refund Policy was last updated on 28 Mar 2023.</p>
      <p>
        This Refund Policy describes Our policies and procedures with regards to
        canceling services and refunds for services.
      </p>
      <p>
        <strong>General Policy</strong>
      </p>
      <p>
        All payments made to {process.env.MONGO_DB_IN_USE == 'UserDB' ? 'Trained By Turp' : 'Body By Reyes'} are non-refundable. Any client
        shall not have any right to a refund for any payment made to Trained By
        Turp for any reason.
      </p>
      <p>
        <strong>Minimum Term</strong>
      </p>
      <p>
        A term shall constitute 30 days starting as soon as the first payment to
        {process.env.MONGO_DB_IN_USE == 'UserDB' ? 'Trained By Turp' : 'Body By Reyes'}rained By Turp is recieved, and renewing at the start of each
        subsequent 30 day period.
      </p>
      <p>
        Payment is owed to {process.env.MONGO_DB_IN_USE == 'UserDB' ? 'Trained By Turp' : 'Body By Reyes'} for each term that any client wishes
        to recieve the services provided, due at the 1st day of each term, and
        no later than the 5th.
      </p>
      <p>
        If any client wishes to cancel services provided by {process.env.MONGO_DB_IN_USE == 'UserDB' ? 'Trained By Turp' : 'Body By Reyes'}, and
        they have not paid for a minimum of 3 terms, then there shall be a
        cancelation fee that is the difference between what they have paid thus
        far, and what 3 terms of that client's chosen services would cost.
      </p>
      <p>
        <strong>Cancelation</strong>
      </p>
      <p>
        Services are provided at will by {process.env.MONGO_DB_IN_USE == 'UserDB' ? 'Trained By Turp' : 'Body By Reyes'}, and subscribed to at
        will by any client. Each party may elect to cancel providing or
        subscribing to services at will at any time for any reason.
      </p>
      <p>
        Should {process.env.MONGO_DB_IN_USE == 'UserDB' ? 'Trained By Turp' : 'Body By Reyes'} elect to stop providing services to a client,
        {process.env.MONGO_DB_IN_USE == 'UserDB' ? 'Trained By Turp' : 'Body By Reyes'} shall not be owed payment by the client.
      </p>
      <p>
        Should any client elect to stop recieving services, {process.env.MONGO_DB_IN_USE == 'UserDB' ? 'Trained By Turp' : 'Body By Reyes'}
        shall be owed payment as pursuant to the Minimum Term section of this
        document.
      </p>
      <p>
        If the client owes {process.env.MONGO_DB_IN_USE == 'UserDB' ? 'Trained By Turp' : 'Body By Reyes'} a cancelation payment as pursuant to
        the Minimum Term section, that shall be the extent of their debt to
        {process.env.MONGO_DB_IN_USE == 'UserDB' ? 'Trained By Turp' : 'Body By Reyes'}.
      </p>
      <p>
        If the client does not owe {process.env.MONGO_DB_IN_USE == 'UserDB' ? 'Trained By Turp' : 'Body By Reyes'} a cancelation payment as
        pursuant to the Minimum Term section, and they elect to cancel their
        subscription to services within the last 3 days ( 72 hours ) of their
        current term, {process.env.MONGO_DB_IN_USE == 'UserDB' ? 'Trained By Turp' : 'Body By Reyes'} is owed their typical payment for the
        subsequent term and is not required to provide services for the
        subsequent term unless the client elects to recieve them and cancel
        thereafter.{" "}
      </p>
    </div>
  );
}
